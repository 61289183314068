import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css';
import React from 'react';
import { Layout } from './Layout';
import Selfie from './screens/Selfie';
import Loading from 'react-fullscreen-loading';
import { ThemeProvider } from '@material-tailwind/react';
import { Home } from './screens/Home';
import { End } from './screens/End';


let router = createBrowserRouter([
  {
    path: "/",
    Component: Layout,
    children: [
      {
        index: true,
        Component: Home
      },
      {
        path: "/selfie",
        Component: Selfie,
      },
      {
        path: "/end",
        Component: End
      }
    ],
  }
])

function App() {
  return (
    <ThemeProvider>
      <RouterProvider router={router} fallbackElement={<Fallback />} />
    </ThemeProvider>
  );
}

export function Fallback() {
  return <Loading loading background="#2ecc71" loaderColor="#3498db" />;
}

export default App;


