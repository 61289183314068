import { useLocation } from "react-router-dom";
import Logo from '../assets/images/full_logo.png';
import { useEffect, useState } from "react";
import Loading from "react-fullscreen-loading";

const BASE_URL = `${process.env.REACT_APP_FIDUCIA_API_URL}/api/v1/services/face/enroll`;

console.log(BASE_URL)

export function End() {
  var state = useLocation().state;

  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState('')

  const handleClose = () => {
    window.opener = null;
    window.open("", "_self");
    window.close();
  }

  useEffect(() => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${state.token}`
      },
      body: JSON.stringify({
        "Images": [
          {
            "imageType": "frontal",
            "imageData": `${state.imageSrc}`
          }
        ]
      })
    };

    fetch(`${BASE_URL}/cadastro_feira_${state.name}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong');
      })
      .then(data => {
        if (data["result"].code !== 1000)
          throw new Error('Something went wrong');

        setResult('success')
      })
      .then(() => setLoading(false))
      .catch(error => {
        setResult('failed');
        setLoading(false);
      })


  }, [state]);

  const renderResult = () => {

    switch (result) {
      case 'success':
        return (<p className="text-3xl font-bold text-center">Cadastro realizado com sucesso!</p>);

      case 'failed':
        return (<p className="text-3xl font-bold text-center">Ocorreu um erro no cadastro!</p>);

      default:
        return;
    }
  }

  return (
    <>
      {loading && (<Loading loading background="#fff" loaderColor="#6BA6EE" />)}
      {!loading && (
        <>
          <header className="flex justify-center top-0 z-50 h-48 w-full bg-neutral-50 ">
            <img src={Logo} alt="Logo" className="object-contain h-48 w-48" />
          </header>

          <main className=" flex flex-col content-center items-center justify-center pt-16 pb-32 h-[calc(100vh-21rem)] bg-neutral-50">
            <div className="p-5">
              {renderResult()}
            </div>
          </main>

          <footer className="flex justify-center bottom-0 z-50 w-full bg-neutral-50 shadow-lg p-10">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded uppercase" onClick={handleClose}>Encerrar</button>
          </footer>
        </>
      )}

    </>
  );
}
