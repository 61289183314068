import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Webcam from 'react-webcam';
import "./Selfie.css"

const videoConstraints = {
    facingMode: "user",
    mirrored: "false"
};

function Selfie() {
    const navigate = useNavigate();
    const webcamRef = React.useRef(null);
    var state = useLocation().state;
    const capture = React.useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            webcamRef.current.stopAndCleanup()
            state["imageSrc"] = imageSrc;
            navigate("/end", { state: state });
        },
        [webcamRef, navigate, state]
    );

    return (
        <div className="App" id="camera">

            <>
                <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                    minScreenshotHeight={480}
                    minScreenshotWidth={640}
                    id="camera--view"
                />
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full uppercase fixed bottom-8 left-[calc(50%-80px)] w-40" onClick={capture}>Capturar</button>
            </>

        </div>
    );
}

export default Selfie;
