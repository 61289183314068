import { useNavigate } from "react-router-dom";

import Logo from '../assets/images/full_logo.png';
import { useState } from "react";


export function Home() {
    const searchParams = new URLSearchParams(document.location.search);
    const token = searchParams.get('token');
    const navigate = useNavigate();

    const [name, setName] = useState('');

    const handleChange = (event) => {
        setName(event.target.value);
    }

    const handleClick = () => {
        navigate("/selfie", { state: { 'token': token, 'name': name } });
    }

    return (
        <>
            <header className="flex justify-center top-0 z-50 h-48 w-full bg-neutral-50 ">
                <img src={Logo} alt="Logo" className="object-contain h-48 w-48" />
            </header>

            <main className=" flex flex-col content-center items-center justify-center pt-16 pb-32 h-[calc(100vh-21rem)] bg-neutral-50">
                <div className="p-5">
                    <p className="text-2xl font-bold text-center">Cadastro de demonstração</p>
                    <p className="italic text-center">Nenhuma informação aqui capturada será usada fora do ambiente desta demonstração</p>
                    <hr className="w-48 h-1 mx-auto my-4 bg-gray-200 border-0 rounded md:my-10 dark:bg-gray-700"></hr>
                </div>
                <div className="p-5">

                    <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Insira o nome a ser utilizado</label>
                    <input type="text" id="first_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5
                     dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Nome" required onChange={handleChange} value={name}></input>
                </div>
            </main>

            <footer className="flex justify-center bottom-0 z-50 w-full bg-neutral-50 shadow-lg p-10">
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded uppercase " onClick={handleClick}>Iniciar</button>
            </footer>
        </>
    );
}